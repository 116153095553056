import { render, staticRenderFns } from "./ProjectsList.vue?vue&type=template&id=54c85d12&scoped=true&functional=true&lang=pug&"
import script from "./ProjectsList.vue?vue&type=script&lang=ts&"
export * from "./ProjectsList.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectsList.vue?vue&type=style&index=0&id=54c85d12&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "54c85d12",
  null
  
)

export default component.exports